import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Card from '../components/ui/cards/card';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Avatar = styled(Img)`
  border-radius: 24px 4px;
  width: 200px;
  border: 1.5px solid var(--img-border);
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    margin-bottom: auto;
    margin-right: 1.5rem;
  }
`;

const Section = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;

  @media (min-width: 768px) {
    flex-flow: row nowrap;
    margin-right: 0;
  }

  p {
    margin: 0;
  }
`;

const IntroTxt = styled.span`
  font-size: 2rem;
  text-align: center;
  width: 100%;
  display: block;
  padding-bottom: 0.75rem;

  @media (min-width: 768px) {
    font-size: 3rem;
    text-align: left;
    display: inline-block;
    padding-bottom: 0;
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      avatar: file(relativePath: { eq: "me.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Home" />
      <Card>
        <Section>
          <div>
            <Avatar
              title="Me!"
              alt="Me!"
              fluid={data.avatar.childImageSharp.fluid}
            />
          </div>
          <p>
            <IntroTxt>I'm Scott Smith.</IntroTxt>
            And I'm a professional front end developer. Having a direct
            influence on the user experience and building the interfaces that
            connect people to services are what drive me to keep learning,
            experimenting, and growing. Over 15 years in the development world
            and there's always more to learn!
          </p>
        </Section>
      </Card>
    </Layout>
  );
};

export default IndexPage;
